<template>
  <div id="services" class="container-fluid bg-light">
      <div class="container-xxl">
          <h2 class="text-primary">{{ $t('services.title') }}</h2>
      </div>
      <div class="container-xxl">
          <div class="row g-5">
            <ServiceElement :cat="card"  v-for="card in cards" :key="card" />
          </div>
      </div>
    <Footer />
  </div>
</template>

<script> 
import Footer from '@/components/Footer.vue'
import ServiceElement from '@/components/ServiceElement'
import cats from '@/locales/de.json'

export default {
  name: 'Services',
  data() {
    return {
      cards: Object.keys(cats.services.cards)
    };
  },
  components: {
    Footer,
    ServiceElement
  },
  mounted() {
    let hash = this.$route.hash.replace('#', '');
    if (hash) {
      document.getElementById(hash).scrollIntoView();
    }
  }
}
</script>

<style scoped>
  #services {
    padding-top: 50px;
    padding-bottom: 50px;
  }

  h2 {
    margin-bottom: 30px;
  }

  .card {
    width: 100%;
    height: 100%;
    padding-bottom: 15px;
    border-bottom: 30px solid #f8f9fa !important;
  }

  .card-img-top {
    text-align: center;
    padding-top: 30px;
  }

  .card-img-top .icon {
    width: 100px;
    height: 100px;
    margin: 0 auto;
    margin-bottom: 5px;
    border-radius: 50%;
    position: relative;
    text-align: center !important;
    display: inline-block;
  }

  .card-img-top .icon span {
    font-size: 60px;
    line-height: 0.9;
    color: #fff;
    position: absolute;
    top: 50%;
    left: 50%;
    -webkit-transform: translate(-50%, -50%);
    -ms-transform: translate(-50%, -50%);
    transform: translate(-50%, -50%);
  }
</style>